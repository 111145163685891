import { useCallback, useEffect } from 'react'

import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { organizationProfile } from 'app/lib/routes'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { CreateAdminBody } from 'app/models/Admin'
import { OrganizationRoute } from 'app/models/scribe.models'
import { useAddAdminMutation } from 'app/redux/scribeApi'

import { AdminForm, AdminTypes } from './AdminForm'

export const AddAdminPage: React.FC = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams() as OrganizationRoute

  const [addAdmin, { isLoading }] = useAddAdminMutation()

  useEffect(() => {
    trackPageView({ title: 'organization-add-admin-page' })
  }, [])

  const navigateToOrganizationProfile = useCallback(() => {
    navigate(organizationProfile.get(organizationId))
  }, [navigate, organizationId])

  const onSubmit = useCallback(
    (params: AdminTypes) => {
      const addAdminBody: CreateAdminBody = {
        type: 'administrator',
        attributes: params,
        relationships: {
          role: {
            data: {
              id: '1',
              type: 'role',
            },
          },
        },
      }

      addAdmin({ organizationId, body: { data: [addAdminBody] } })
        .unwrap()
        .then(navigateToOrganizationProfile)

      trackSelfDescribingEvent({
        event: {
          schema: getSnowplowSchema('button_click', '1-0-0'),
          data: {
            button_value: `Submit Add Admin Form on Presto`,
          },
        },
      })
    },
    [organizationId, addAdmin, navigateToOrganizationProfile],
  )

  return (
    <Page isLoading={isLoading}>
      <AdminForm onSubmit={onSubmit} onCancel={navigateToOrganizationProfile} />
    </Page>
  )
}
