import { Button, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { organizationMemberProfileChangePlan } from 'app/lib/routes'
import { EligibilityRecordRoute, Plan } from 'app/models/scribe.models'

type Props = {
  plan: Plan
}

export const PlanDetails = ({ plan }: Props) => {
  const { organizationId, eligibilityRecordId } = useParams() as EligibilityRecordRoute
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToChangeMemberPlan = () => {
    navigate(organizationMemberProfileChangePlan.get(organizationId, eligibilityRecordId))
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h2">{t('eligibilityRecordPage.subtitle.memberPlan')}</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="contained" onClick={navigateToChangeMemberPlan}>
            {t('eligibilityRecordPage.buttons.changePlan')}
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xxl={4}>
          <PlanCard plan={plan} />
        </Grid>
      </Grid>
    </>
  )
}
