import { startOfDay } from 'date-fns'
import { boolean, InferType, object, string } from 'yup'

import { today, toISOdate } from 'app/lib/utils/date'
import {
  requiredDateSchema,
  requiredEmailSchema,
  requiredStringSchema,
} from 'app/lib/utils/yupSchemas'
import {
  AccountingMethod,
  BillingMethod,
  CreditCardEnum,
  LanguagePreference,
  MemberIdType,
} from 'app/models/scribe.models'

const baseSchema = object({
  name: requiredStringSchema,
  displayNameEnglish: string().trim(),
  displayNameFrench: string().trim(),
  memberIdType: requiredStringSchema.oneOf(Object.values(MemberIdType), 'form.errors.required'),
  streetAddress: string().trim(),
  suite: string().trim(),
  city: string().trim(),
  province: requiredStringSchema,
  postalCode: string().trim(),
  emailPreference: requiredStringSchema.oneOf(
    Object.values(LanguagePreference),
    'form.errors.required',
  ),
  clientNotes: string().trim(),
  careTeamNotes: string().trim(),
  forcedMfa: boolean().default(true),
  reportsEnabled: boolean().default(false),
  enrolmentCode: string().trim(),
  communicationsAllowed: boolean().default(false),
}).required()

export const reportingColumnsSchema = object({
  name: requiredStringSchema,
  attributeSchema: object({
    reportingColumnOne: string(),
    reportingColumnTwo: string(),
    reportingColumnThree: string(),
    reportingColumnFour: string(),
    reportingColumnFive: string(),
    reportingColumnSix: string(),
    reportingColumnSeven: string(),
  }),
}).required()

export const createOrganizationSchema = baseSchema
  .shape({
    billingEmail: requiredEmailSchema,
    billingMethod: requiredStringSchema.oneOf(Object.values(BillingMethod), 'form.errors.required'),
    accountingMethod: requiredStringSchema
      .oneOf(Object.values(AccountingMethod), 'form.errors.required')
      .default(AccountingMethod.Automatic),
    billingStartDate: requiredDateSchema.min(
      toISOdate(startOfDay(today())),
      'organizationPage.form.errors.billingStartDate.min',
    ),
  })
  .required()

export const editFullOrganizationSchema = baseSchema
  .shape({
    billingEmail: requiredEmailSchema,
    billingMethod: requiredStringSchema.oneOf(
      Object.values({ ...BillingMethod, ...CreditCardEnum }),
      'form.errors.required',
    ),
    billingStartDate: requiredDateSchema,
  })
  .required()

export const editRestrictedOrganizationSchema = baseSchema

export type CreateOrganizationFormData = InferType<typeof createOrganizationSchema>
export type EditFullOrganizationFormData = InferType<typeof editFullOrganizationSchema>
export type EditRestrictedOrganizationFormData = InferType<typeof editRestrictedOrganizationSchema>
export type reportingColumnFormData = InferType<typeof reportingColumnsSchema>

export type OrganizationFormData =
  | CreateOrganizationFormData
  | EditFullOrganizationFormData
  | EditRestrictedOrganizationFormData

export type ReportingFormData = reportingColumnFormData
