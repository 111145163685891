import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, TableCell, TableRow, TextField, Typography } from '@mui/material'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { object } from 'yup'

import { UploadFormField } from 'app/lib/components/form/UploadFormField'
import { SortOrder } from 'app/lib/legacy-api/scribe.api'
import { getPlansThatContainOption, getSnowplowSchema } from 'app/lib/utils/helpers'
import { fileSchema, requiredStringSchema } from 'app/lib/utils/yupSchemas'
import {
  EligibilityRecord,
  EligibilityRecordStatus,
  OrganizationRoute,
  ServiceOptionLabels,
} from 'app/models/scribe.models'
import {
  useAddActionRequestMutation,
  useGetOrganizationPlansQuery,
  useLazyListEligibilityRecordsQuery,
} from 'app/redux/scribeApi'
import { colors } from 'app/theme'

import { DEFAULT_LIMIT, SearchMember } from '../search-member/SearchMember'

export type SubmittedMember = { id: number; name: string }

type RequestFormData = {
  file?: any // Yup schemas can't validate a file explicitly, which is why this type is generic
  member: string
}

const defaultValues: RequestFormData = {
  member: '',
}

const schema = object({
  member: requiredStringSchema,
  file: fileSchema,
}).required()

export const RequestForm: React.FC = () => {
  const { t } = useTranslation()
  const { organizationId } = useParams() as OrganizationRoute

  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false)
  const [onSelectModalClose, setOnSelectModalClose] = useState<boolean>(false)
  const [fetchEligibilityRecords, { data: records, isFetching, isError }] =
    useLazyListEligibilityRecordsQuery()
  const [selectedMember, setSelectedMember] = useState<SubmittedMember | null>(null)
  const { data: plans = [] } = useGetOrganizationPlansQuery(organizationId)

  const [addActionRequest, { isLoading, isSuccess }] = useAddActionRequestMutation()

  const methods = useForm<RequestFormData>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { formState, setValue, watch } = methods

  const selectedFile = watch('file')

  const isSubmitDisabled = !formState.isValid

  useEffect(() => {
    if (isSuccess) {
      methods.reset()
      setSelectedMember(null)
    }
  }, [isSuccess, methods, setValue])

  const idsOfWorkplaceReferralPlan = useMemo(() => {
    const filteredPlans = getPlansThatContainOption(plans, ServiceOptionLabels.WORKPLACE_REFERRAL)

    return filteredPlans.map((plan) => plan.id)
  }, [plans])

  const fetchRecords = (page: number, search?: string | null) => {
    const params = {
      order: SortOrder.DESCENDING,
      offset: DEFAULT_LIMIT * page,
      limit: DEFAULT_LIMIT,
      search: search || undefined,
      organizationId: [Number(organizationId)],
      status: EligibilityRecordStatus.ACTIVE,
      plan_id: idsOfWorkplaceReferralPlan,
    }

    fetchEligibilityRecords({ params })
  }

  const rows = records?.data?.map((record: EligibilityRecord) => (
    <TableRow key={record.id} hover onClick={() => handleSelectMember(record)} data-testid="member">
      <TableCell>
        <Typography variant="subtitle2">{record.attributes.firstName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">{record.attributes.lastName}</Typography>
      </TableCell>
      <TableCell title={record.attributes.uniqueIdentifier}>
        <Typography variant="body2">{record.attributes.uniqueIdentifier}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Typography variant="subtitle2" sx={{ color: 'accent.main' }}>
          {t('searchMember.modal.select')}
        </Typography>
      </TableCell>
    </TableRow>
  ))

  const tableData = {
    rows: rows || [],
    totalItems: records?.meta.totalItems,
  }

  const handleMemberSearchClick = () => {
    trackSelfDescribingEvent({
      event: {
        schema: getSnowplowSchema('button_click', '1-0-0'),
        data: {
          button_value: 'EAP Request - select member',
        },
      },
    })
    setOnSelectModalClose(false)
    setOpenSearchModal(true)
  }

  const handleSelectedMember = useCallback(
    (member: SubmittedMember) => {
      setSelectedMember(member)
      methods.setValue('member', member.name, { shouldValidate: true })
    },
    [setSelectedMember, methods],
  )

  const handleClearSelected = () => {
    methods.setValue('member', '', { shouldValidate: true })
    setSelectedMember(null)
  }

  const onClose = useCallback(() => {
    setOpenSearchModal(false)
  }, [])

  const handleSelectFile = (file: File) => {
    setValue('file', file, { shouldValidate: true })
  }

  const handleRemoveFile = () => {
    setValue('file', null, { shouldValidate: true })
  }

  const handleSelectMember = useCallback(
    (record: EligibilityRecord) => {
      handleSelectedMember({
        id: record.id,
        name: `${record.attributes.firstName} ${record.attributes.lastName}`,
      })

      setOnSelectModalClose(true)
      onClose()
    },
    [handleSelectedMember, onClose],
  )

  const onSubmit = (data: RequestFormData) => {
    if (!selectedMember) {
      return
    }

    trackSelfDescribingEvent({
      event: {
        schema: getSnowplowSchema('button_click', '1-0-0'),
        data: {
          button_value: `EAP Request - request form submit - Eligibility Record Id: ${selectedMember.id}`,
        },
      },
    })

    const body = new FormData()
    body.append('document', data.file, data.file.name)

    addActionRequest({ eligibilityRecordId: selectedMember.id, body })
  }

  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h2" mb={2}>
          {t('organizationEapRequests.form.heading')}
        </Typography>
        <Typography variant="body1" mb={4} color={colors.tertiary}>
          {t('organizationEapRequests.form.description')}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography
              variant="caption1"
              color="dimgrey"
              sx={{ display: 'block', marginBottom: 0.5 }}
            >
              <label htmlFor="member">{t('organizationEapRequests.form.whoIsItFor')}</label>
            </Typography>
            <Box sx={{ position: 'relative', width: 325 }}>
              <Controller
                name="member"
                render={({ field }) => (
                  <TextField
                    id="member"
                    {...field}
                    onClick={handleMemberSearchClick}
                    variant="outlined"
                    size="small"
                    required
                    placeholder={t('organizationEapRequests.form.selectMember')}
                    sx={{ width: '100%' }}
                    inputProps={{
                      readOnly: true,
                      style: {
                        fontSize: '0.875em',
                      },
                      'data-testid': 'search-member-input',
                    }}
                  />
                )}
              />
              {selectedMember && (
                <button
                  data-testid="clear-member"
                  onClick={handleClearSelected}
                  style={{
                    position: 'absolute',
                    top: 2,
                    right: 2,
                    bottom: 2,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '6px',
                    background: colors.snow,
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <HighlightOffIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </button>
              )}
            </Box>
            <Typography variant="caption1" mt={4} mb={0.5} display="block" color="dimgrey">
              {t('organizationEapRequests.form.uploadHeading')}
            </Typography>
            <UploadFormField
              label={t('organizationEapRequests.form.selectFile')}
              accept=".pdf"
              selectedFile={selectedFile}
              onChange={handleSelectFile}
              onReset={handleRemoveFile}
            />
            <Box display="flex" justifyContent="end" mt={{ xs: 4, md: 8 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitDisabled}
                loading={isLoading}
                data-testid="submit-request"
              >
                {t('organizationEapRequests.form.submit')}
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
        <SearchMember
          open={openSearchModal}
          title={t('searchMember.modal.titleRequestForm')}
          onClose={onClose}
          handleFetch={fetchRecords}
          onSelectModalClose={onSelectModalClose}
          isFetching={isFetching}
          isError={isError}
          tableData={tableData}
        />
      </CardContent>
    </Card>
  )
}
