import {
  TableBody,
  TableCell,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { AdminAreaTrKeys } from 'app/lib/constants'
import { formatDate } from 'app/lib/utils/date'
import { getProvinceName } from 'app/lib/utils/helpers'
import { EligibilityRecord } from 'app/models/scribe.models'
import { getApplicationConfig } from 'config'

import { DEFAULT_LIMIT } from '..'

interface Props {
  records: ReadonlyArray<EligibilityRecord>
  page: number
  onPageChange: (page: number) => void
  count: number
  plansIdToLabel: {
    [key: string]: string
  }
}

export type HeaderValues = {
  uniqueIdentifier: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  province?: string
  activationDate: string
  deactivationDate: string
}

interface HeaderType {
  name: keyof HeaderValues
  width?: number
}

const { region } = getApplicationConfig()

const HEADER_NAMES = [
  { name: 'uniqueIdentifier', width: 275 },
  { name: 'firstName' },
  { name: 'lastName' },
  { name: 'dateOfBirth' },
  { name: 'planLabel' },
  { name: AdminAreaTrKeys[region] },
  { name: 'activationDate' },
  { name: 'deactivationDate' },
] as HeaderType[]

export const TableDisplay: React.FC<Props> = ({
  records,
  page,
  onPageChange,
  count,
  plansIdToLabel,
}) => {
  const { t: tHeader } = useTranslation(undefined, {
    keyPrefix: 'eligibilityRecordListPage.eligibilityRecordsTable.headers',
  })
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const cells = HEADER_NAMES.map(({ name, width }) => (
    <TableCell key={name} sx={{ width }} align="left">
      {tHeader(name)}
    </TableCell>
  ))

  const rows = records.map(({ id, organizationId, attributes, eligibleIntervals }) => {
    const eligibleInterval = eligibleIntervals[eligibleIntervals.length - 1]
    return (
      <ClickableRow key={id} to={`/organizations/${organizationId}/members/${id}`} testId={id}>
        <TableCell
          title={attributes.uniqueIdentifier}
          sx={{ maxWidth: 275, overflowWrap: 'break-word' }}
        >
          {attributes.uniqueIdentifier}
        </TableCell>
        <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
          <Typography variant="subtitle2">{attributes.firstName}</Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
          <Typography variant="subtitle2">{attributes.lastName}</Typography>
        </TableCell>
        <TableCell>
          {attributes.dateOfBirth && formatDate(attributes.dateOfBirth, resolvedLanguage)}
        </TableCell>
        <TableCell>{eligibleInterval ? plansIdToLabel[eligibleInterval.planId] : '-'}</TableCell>
        <TableCell>{attributes.province && getProvinceName(attributes.province, '', t)},</TableCell>
        <TableCell>
          {eligibleInterval ? formatDate(eligibleInterval.startDate, resolvedLanguage) : '-'}
        </TableCell>
        <TableCell>
          {eligibleInterval ? formatDate(eligibleInterval.endDate, resolvedLanguage) : '-'}
        </TableCell>
      </ClickableRow>
    )
  })

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="eligibility-record-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={DEFAULT_LIMIT}
              rowsPerPageOptions={[]}
              count={count}
              showFirstButton
              showLastButton
              onPageChange={(_event, pageValue) => onPageChange(pageValue)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
              labelDisplayedRows={({ from, to, count: itemsCount }) =>
                itemsCount > 0
                  ? t('global.pagination.of', { from, to, count: itemsCount })
                  : t('global.pagination.of_more_than', { from, to })
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
