import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Dialog, Link } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { fromISOdate, getEarliestActivationDate, today, toISOdate } from 'app/lib/utils/date'
import { getLatestOfTwoDates, getPlansIdToLabel } from 'app/lib/utils/helpers'
import {
  prepareDirectives,
  processDirectives,
  ProcessingResults,
  processingResultsInitial,
} from 'app/lib/utils/process-directives'
import { parseCsv, ValidationError } from 'app/lib/utils/validate-csv-import'
import {
  EligibilityDirective as Directive,
  SubmitEligibilityDirectiveArgs,
} from 'app/models/EligibilityRecord'
import { Organization, OrganizationRoute } from 'app/models/scribe.models'
import {
  useGetOrganizationPlansQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'
import { getApplicationConfig } from 'config'

import { Import, ImportTypes } from './Import'
import { Preview } from './Preview'
import { Progress } from './Progress'
import { Summary } from './Summary'

export enum ErrorTypes {
  ENCODING = 'encoding',
  SIZE = 'size',
  MISSING_COLUMNS = 'missingColumns',
  RESERVED_ATTRIBUTE = 'reservedAttribute',
}

enum ImportStep {
  FILE_IMPORT = 1,
  PREVIEW_PROCESSING = 2,
  PREVIEW = 3,
  FINAL_PROCESSING = 4,
  FINAL = 5,
}

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  organizationType: Organization['memberIdType']
  sendEmailEnabled: Organization['communicationsAllowed']
  organizationBillingStartDate: Organization['billingStartDate']
}

const assetsURL = getApplicationConfig().assets_url

export const CsvUpload: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  organizationType,
  sendEmailEnabled,
  organizationBillingStartDate,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const [mutationAction] = useSubmitEligibilityDirectiveMutation()
  const submitEligibilityDirectiveMutation = useCallback(
    (props: SubmitEligibilityDirectiveArgs) =>
      mutationAction({
        ...props,
        extraOptions: {
          showErrorTooltip: false,
        },
      }).unwrap(),
    [mutationAction],
  )
  const [step, setStep] = useState<number>(1)
  const [importType, setImportType] = useState<ImportTypes>(ImportTypes.ADD)
  const [file, setFile] = useState<File | null>(null)
  const { organizationId } = useParams() as OrganizationRoute
  const [fileDirectives, setFileDirectives] = useState<Directive[]>([])
  const userPermissions = useUserPermissions()
  const earliestActivationDate = getEarliestActivationDate(
    organizationBillingStartDate,
    userPermissions,
  )
  const defaultActivationDate = organizationBillingStartDate
    ? getLatestOfTwoDates(fromISOdate(organizationBillingStartDate), today())
    : today()
  const [activationDate, setActivationDate] = useState<Date | null>(defaultActivationDate)
  const [validationError, setValidationError] = useState<string | React.ReactNode>('')
  const [processingResults, setProcessingResults] =
    useState<ProcessingResults>(processingResultsInitial)
  const [percentageOfProcessedDirectives, setPercentageOfProcessedDirectives] = useState<number>(0)
  const { data: plans = [] } = useGetOrganizationPlansQuery(organizationId)

  let plansIdToLabel = useMemo(() => getPlansIdToLabel(plans), [plans])
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setStep(ImportStep.FILE_IMPORT)
  }, [])

  const handlePreviewProcessing = useCallback(() => {
    setStep(ImportStep.PREVIEW_PROCESSING)
    const directives = prepareDirectives(fileDirectives, toISOdate(defaultActivationDate))
    processDirectives({
      getAccessTokenSilently,
      organizationId,
      directives,
      importType,
      isPreview: true,
      setPercentageOfProcessedDirectives,
      plansIdToLabel,
      submitEligibilityDirectiveMutation,
    }).then((results) => {
      setProcessingResults(results)
      setStep(ImportStep.PREVIEW)
    })
  }, [
    fileDirectives,
    importType,
    organizationId,
    getAccessTokenSilently,
    defaultActivationDate,
    plansIdToLabel,
    submitEligibilityDirectiveMutation,
  ])

  const handleFinalProcessing = useCallback(() => {
    const directives = prepareDirectives(fileDirectives, toISOdate(defaultActivationDate))
    setStep(ImportStep.FINAL_PROCESSING)
    // replace the activation date with the date from the form for created records
    // requires preview to run first
    if (activationDate && activationDate !== defaultActivationDate) {
      processingResults.activatedRecords.forEach((activatedRecord) => {
        const idx = directives.findIndex(
          (d) => d.uniqueIdentifier === activatedRecord.uniqueIdentifier,
        )
        directives[idx].activationDate = toISOdate(activationDate)
      })
    }

    processDirectives({
      getAccessTokenSilently,
      organizationId,
      directives,
      importType,
      isPreview: false,
      setPercentageOfProcessedDirectives,
      plansIdToLabel,
      submitEligibilityDirectiveMutation,
    }).then((results) => {
      setProcessingResults(results)
      setStep(ImportStep.FINAL)
    })
  }, [
    getAccessTokenSilently,
    setProcessingResults,
    organizationId,
    fileDirectives,
    importType,
    processingResults,
    activationDate,
    defaultActivationDate,
    plansIdToLabel,
    submitEligibilityDirectiveMutation,
  ])

  const handleClose = (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason) {
      return
    }
    onClose()
  }

  const handleReplaceFile = () => {
    setFile(null)
    setFileDirectives([])
    setValidationError('')
  }

  const getErrorMessage = (errorType: ErrorTypes, column?: string): ReactNode | string => {
    switch (errorType) {
      case ErrorTypes.ENCODING:
        const url = `https://intercom.help/dialogue/${resolvedLanguage}/articles/4095321-csv-encoding`
        return (
          <Trans i18nKey="csvImport.fileError.encoding">
            {/* eslint-disable-next-line */}
            Consult our <Link href={url}>FAQ</Link> for supported file encodings.
          </Trans>
        )
      case ErrorTypes.RESERVED_ATTRIBUTE:
        return t(`csvImport.fileError.${errorType}`, { column })
      default:
        return t(`csvImport.fileError.${errorType}`, t('global.unknownError'))
    }
  }

  const handleFileDrop = (f: File) => {
    handleReplaceFile()
    parseCsv(f, organizationType)
      .then((directives) => {
        setFile(f)
        setFileDirectives(directives)
      })
      .catch((err: ValidationError) => {
        const displayedError = getErrorMessage(err.type, err.column)
        setValidationError(displayedError)
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="csv-import-preview"
      open={open}
    >
      {step === ImportStep.FILE_IMPORT && (
        <Import
          onClose={onClose}
          onNext={handlePreviewProcessing}
          importType={importType}
          setImportType={setImportType}
          file={file}
          onFileDrop={handleFileDrop}
          onReplaceFile={handleReplaceFile}
          isFileValidated={!!fileDirectives.length}
          error={validationError}
          organizationType={organizationType}
          assetsURL={assetsURL}
        />
      )}
      {step === ImportStep.PREVIEW_PROCESSING && (
        <Progress
          percentage={percentageOfProcessedDirectives}
          bodyText={t('csvImport.progress.preview.body')}
        />
      )}
      {step === ImportStep.PREVIEW && (
        <Preview
          activationDate={activationDate}
          onActivationDateChange={setActivationDate}
          sendEmailEnabled={sendEmailEnabled}
          processingResults={processingResults}
          activationMinDate={earliestActivationDate}
          onClose={onClose}
          onNext={handleFinalProcessing}
          onBack={() => setStep(ImportStep.FILE_IMPORT)}
        />
      )}
      {step === ImportStep.FINAL_PROCESSING && (
        <Progress
          percentage={percentageOfProcessedDirectives}
          bodyText={t('csvImport.progress.summary.body')}
        />
      )}
      {step === ImportStep.FINAL && (
        <Summary processingResults={processingResults} onClose={onSubmit} />
      )}
    </Dialog>
  )
}
