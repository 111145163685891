import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import InputFormField, { InputFormFieldProps } from 'app/lib/components/form/InputFormField'
import SelectFormField, { SelectFormFieldProps } from 'app/lib/components/form/SelectFormField'
import { AdminAreaTrKeys, REGIONS, ScribePermission } from 'app/lib/constants'
import withInputFieldViewMode from 'app/lib/hoc/withInputFieldViewMode'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { getProvinceName } from 'app/lib/utils/helpers'
import { isRegion } from 'app/lib/utils/regions'
import { LanguagePreference, MemberIdType } from 'app/models/scribe.models'
import { useGetAdminAreasQuery } from 'app/redux/scribeApi'
import { getApplicationConfig } from 'config'

import { FormRow } from './FormRow'

const InputFormFieldWithViewMode = withInputFieldViewMode<InputFormFieldProps>(InputFormField)
const SelectFormFieldWithViewMode = withInputFieldViewMode<SelectFormFieldProps>(SelectFormField)

const { region } = getApplicationConfig()
const adminAreaKey = AdminAreaTrKeys[region]

const isUSRegion = isRegion(REGIONS.US)

export const Profile: React.FC<{ isEdit: boolean; nonEditableFields: string[] }> = ({
  isEdit,
  nonEditableFields,
}) => {
  const { t } = useTranslation()
  const permissions = useUserPermissions()

  const { watch } = useFormContext()

  const memberIdType: MemberIdType = watch('memberIdType')

  const { data: availableAreas = [] } = useGetAdminAreasQuery(region)

  const canReadNotesSection = permissions?.includes(ScribePermission.CREATE_ORGANIZATION)

  return (
    <Card>
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" mb={2}>
              {t('organizationPage.form.profile.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.name')}
              field={
                <InputFormFieldWithViewMode
                  name="name"
                  label={t('organizationPage.form.profile.name')}
                  isViewMode={nonEditableFields.includes('name')}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.memberIdType')}
              field={
                <SelectFormFieldWithViewMode
                  isViewMode={isEdit}
                  name="memberIdType"
                  variant="outlined"
                  displayName={t(`global.memberIdTypeStatus.${memberIdType}`)}
                  label={t('organizationPage.form.profile.memberIdType')}
                  options={Object.values(MemberIdType).map((value) => ({
                    label: t(`global.memberIdTypeStatus.${value}`),
                    value,
                  }))}
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t(
                `organizationPage.form.profile.${
                  isUSRegion ? 'displayName' : 'displayNameEnglish'
                }`,
              )}
              field={
                <InputFormField
                  name="displayNameEnglish"
                  label={t(
                    `organizationPage.form.profile.${
                      isUSRegion ? 'displayName' : 'displayNameEnglish'
                    }`,
                  )}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          {!isUSRegion && (
            <Grid item xs={12} md={6}>
              <FormRow
                label={t('organizationPage.form.profile.displayNameFrench')}
                field={
                  <InputFormField
                    name="displayNameFrench"
                    label={t('organizationPage.form.profile.displayNameFrench')}
                    variant="outlined"
                    hideLabel
                  />
                }
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.streetAddress')}
              field={
                <InputFormFieldWithViewMode
                  isViewMode={nonEditableFields.includes('streetAddress')}
                  name="streetAddress"
                  label={t('organizationPage.form.profile.streetAddress')}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.suite')}
              field={
                <InputFormFieldWithViewMode
                  isViewMode={nonEditableFields.includes('suite')}
                  name="suite"
                  label={t('organizationPage.form.profile.suite')}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.city')}
              field={
                <InputFormFieldWithViewMode
                  isViewMode={nonEditableFields.includes('city')}
                  name="city"
                  label={t('organizationPage.form.profile.city')}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t(`organizationPage.form.profile.${adminAreaKey}`)}
              field={
                <SelectFormFieldWithViewMode
                  isViewMode={nonEditableFields.includes('province')}
                  name="province"
                  variant="outlined"
                  label={t(`organizationPage.form.profile.${adminAreaKey}`)}
                  hideLabel
                  options={availableAreas.map(({ isoCode, name }) => ({
                    label: getProvinceName(isoCode, name, t),
                    value: isoCode,
                  }))}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.profile.postalCode')}
              field={
                <InputFormFieldWithViewMode
                  isViewMode={nonEditableFields.includes('postalCode')}
                  name="postalCode"
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          {!isUSRegion && (
            <Grid item xs={12} md={6}>
              <FormRow
                label={t('organizationPage.form.profile.emailPreference')}
                field={
                  <SelectFormField
                    name="emailPreference"
                    variant="outlined"
                    label={t('organizationPage.form.profile.emailPreference')}
                    hideLabel
                    options={Object.values(LanguagePreference).map((value) => ({
                      label: t(`global.languagePreference.${value}`),
                      value,
                    }))}
                  />
                }
              />
            </Grid>
          )}

          {canReadNotesSection && (
            <>
              <Grid item xs={12}>
                <Typography variant="h2" my={2}>
                  {t('organizationPage.form.notes.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormRow
                  label={t('organizationPage.form.notes.clientNotes')}
                  sublabel={t('organizationPage.form.notes.clientNotesDesc')}
                  field={
                    <InputFormFieldWithViewMode
                      name="clientNotes"
                      label={t('organizationPage.form.notes.clientNotes')}
                      isViewMode={nonEditableFields.includes('clientNotes')}
                      variant="outlined"
                      multiline
                      minRows={3}
                      maxRows={100}
                      hideLabel
                    />
                  }
                  justify="flex-start"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormRow
                  label={t('organizationPage.form.notes.careTeamNotes')}
                  sublabel={t('organizationPage.form.notes.careTeamNotesDesc')}
                  field={
                    <InputFormFieldWithViewMode
                      name="careTeamNotes"
                      label={t('organizationPage.form.notes.careTeamNotes')}
                      isViewMode={nonEditableFields.includes('careTeamNotes')}
                      variant="outlined"
                      multiline
                      minRows={3}
                      maxRows={100}
                      hideLabel
                    />
                  }
                  justify="flex-start"
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
