import { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'

import { CollapsibleResults } from 'app/lib/components/CollapsibleResults'
import { isSameDayOrLater, today } from 'app/lib/utils/date'
import { ProcessingResults } from 'app/lib/utils/process-directives'

interface Props {
  activationDate: Date | null
  onActivationDateChange: (date: Date | null) => void
  sendEmailEnabled: boolean
  processingResults: ProcessingResults
  activationMinDate: Date
  onNext: () => void
  onClose: () => void
  onBack: () => void
}

export const Preview: React.FC<Props> = ({
  activationDate = today(),
  onActivationDateChange,
  sendEmailEnabled,
  processingResults,
  activationMinDate,
  onNext,
  onBack,
  onClose,
}) => {
  const [isValidated, setIsValidated] = useState<boolean>(true)

  const { t } = useTranslation()

  const handleOnChange = (value: Date | null) => {
    onActivationDateChange(value)
    setIsValidated(Boolean(value && isSameDayOrLater(value, activationMinDate)))
  }

  return (
    <>
      <DialogTitle>
        {t('csvImport.preview.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CollapsibleResults processingResults={processingResults} />
        {!!processingResults.activatedRecords.length && (
          <Box sx={{ mt: 4 }}>
            <DatePicker
              label={t('csvImport.preview.activationDate.label')}
              format="yyyy-MM-dd"
              views={['year', 'month', 'day']}
              openTo="month"
              value={activationDate}
              onChange={handleOnChange}
              minDate={activationMinDate}
              slotProps={{ textField: { inputProps: { sx: { minWidth: '224px' } } } }}
            />
          </Box>
        )}
        <Typography variant="caption2">
          {sendEmailEnabled
            ? t('csvImport.preview.sendNewMembersAnInviteAllowed')
            : t('csvImport.preview.sendNewMembersAnInviteNotAllowed')}
        </Typography>
        <DialogActions>
          <Button onClick={onBack}>{t('global.dialog.back')}</Button>
          <Button
            onClick={onNext}
            variant="contained"
            data-testid="continue-button"
            disabled={!isValidated}
          >
            {t('global.dialog.continue')}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  )
}
