import { useTranslation } from 'react-i18next'

import { CONNECTION_TYPES } from '../connections'

import BaseConnection from './BaseConnection'

const Adp = () => {
  const { t } = useTranslation()
  return (
    <BaseConnection
      title={t('loginConnectionPage.adp.title')}
      buttonText={t('loginConnectionPage.adp.loginButton')}
      connection={CONNECTION_TYPES.ADP}
      customPopup={{
        height: '660',
        width: '510',
      }}
    />
  )
}

export default Adp
