import { useCallback, useEffect, useState } from 'react'

import { Box, Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { BlueBadge } from 'app/lib/components/BlueBadge'
import { NoResults } from 'app/lib/components/NoResults'
import { UserInfoTable } from 'app/lib/components/UserInfoTable'
import { FamilyMemberType, Pagination } from 'app/models/scribe.models'
import { useGetUserEpisodesQuery } from 'app/redux/erApi'
import {
  useGetUserChargesQuery,
  useGetUserFamilyQuery,
  useGetUserQuery,
  useGetUserEligibilityRecordsQuery,
} from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { Charges } from './Charges'
import { EligibilityRecord } from './EligibilityRecords'
import { Episodes } from './Episodes'
import { FamilyMembers } from './FamilyMembers'
import { UserEmail } from './UserEmail'

export const DEFAULT_LIMIT = 5

export const UserProfilePage = () => {
  const { t } = useTranslation()
  const { userId = '' } = useParams()
  const userQuery = useGetUserQuery(userId)
  const user = userQuery.data
  const userFamilyQuery = useGetUserFamilyQuery(userId)
  const userFamily = userFamilyQuery.data
  const { data: userCharges } = useGetUserChargesQuery(userId)
  const initializePagination = (limit = DEFAULT_LIMIT) => ({
    offset: 0,
    limit,
    total: 0,
  })
  const [episodesPagination, setEpisodesPagination] = useState(initializePagination())
  const [eligibilityRecordsPagination, setEligibilityRecordsPagination] =
    useState(initializePagination())
  const [chargesPagination, setChargesPagination] = useState(initializePagination())

  const { data: userEpisodes } = useGetUserEpisodesQuery({
    patientId: userId,
    params: episodesPagination,
  })

  const { data: userEligibilityRecords } = useGetUserEligibilityRecordsQuery({
    userId: userId,
    params: eligibilityRecordsPagination,
  })

  const eligibilityRecordAmount = userEligibilityRecords?.meta?.totalItems ?? 0
  const episodesAmount = userEpisodes?.meta?.totalItems ?? 0

  const usePagination = (
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>,
    amount: number,
    pagination: Pagination,
  ) => {
    const handlePagination = useCallback(
      (params: Partial<Pagination>) => {
        setPagination((prev) => ({ ...prev, ...params }))
      },
      [setPagination],
    )

    useEffect(() => {
      if (amount !== pagination.total) {
        handlePagination({ total: amount })
      }
    }, [amount, handlePagination, pagination.total])

    return handlePagination
  }

  const handleEpisodesPagination = usePagination(
    setEpisodesPagination,
    episodesAmount,
    episodesPagination,
  )

  const handleEligibilityRecordsPagination = usePagination(
    setEligibilityRecordsPagination,
    eligibilityRecordAmount,
    eligibilityRecordsPagination,
  )

  const loading = userQuery.isLoading && userFamilyQuery.isLoading

  const title =
    user && (user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email)

  const currentFamilyMember = userFamily?.data?.find((el) => el.id === userId)
  const isFamilyGuardian =
    !userFamily?.data?.length || currentFamilyMember?.type === FamilyMemberType.GUARDIAN

  const pageTitle = `${title} (${t('userProfile.info.id')}: ${user?.id})`

  return (
    <Page
      isLoading={loading}
      error={
        userQuery.isError && (
          <NoResults
            title={t('userProfile.notFound')}
            img={<NoSearchResults title="no_results" />}
          />
        )
      }
    >
      <Box display="flex" alignItems="center" gap={2} mb={9}>
        <BlueBadge>{t('userProfile.userAccount')}</BlueBadge>
        <Typography variant="h1" data-testid="page-title">
          {pageTitle}
        </Typography>
      </Box>
      <Typography variant="h2" mb={6}>
        {t('userProfile.profileDetails')}
      </Typography>
      <Card sx={{ p: 4 }}>
        <UserInfoTable userId={userId} />
      </Card>
      {user && <UserEmail userId={user.id} authId={user.authId} email={user.email} key={user.id} />}
      {userEligibilityRecords?.data && (
        <EligibilityRecord
          eligibilityRecords={userEligibilityRecords?.data || []}
          pagination={eligibilityRecordsPagination}
          setPagination={handleEligibilityRecordsPagination}
          pageTitle={pageTitle}
        />
      )}
      {userFamily?.data && (
        <FamilyMembers
          data={userFamily?.data}
          userId={userId}
          isFamilyGuardian={isFamilyGuardian}
        />
      )}
      {userEpisodes?.data && (
        <Episodes
          episodesData={userEpisodes?.data || []}
          pagination={episodesPagination}
          setPagination={handleEpisodesPagination}
        />
      )}
      {userCharges?.data && (
        <Charges
          pagination={chargesPagination}
          setPagination={setChargesPagination}
          chargesData={userCharges?.data || []}
        />
      )}
    </Page>
  )
}
