import { max } from 'date-fns'
import { TFunction } from 'i18next'

import enTranslations from 'app/i18n/locales/en.json'
import { isArchivedV2 } from 'app/models/Organization'
import { PlanWithServices } from 'app/models/scribe.models'

export const FR_TO_EN_COLUMNS: { [id: string]: string } = {
  identifiant_unique: 'unique_identifier',
  prenom: 'first_name',
  nom_de_famille: 'last_name',
  date_de_naissance: 'date_of_birth',
  courriel_de_communication: 'communication_email',
  forfait: 'plan',
  date_d_activation: 'activation_date',
  date_de_desactivation: 'deactivation_date',
}
export const EN_TO_FR_COLUMNS = Object.fromEntries(
  Object.entries(FR_TO_EN_COLUMNS).map((a) => a.reverse()),
)

export const translate = (target: string, dictionary: { [frKey: string]: string }) => {
  const header = target.trim().toLowerCase()
  return header in dictionary ? dictionary[header] : header
}

export const getSnowplowSchema = (name: string, version: string) => {
  return `iglu:co.dialogue/${name}/jsonschema/${version}`
}

export const getLatestOfTwoDates = (firstDate: Date, secondDate: Date): Date =>
  max([firstDate, secondDate])

export const getPlansThatContainOption = (
  plans: PlanWithServices[],
  requestedOption: string,
): PlanWithServices[] => {
  const activePlans = plans.filter((plan) => !isArchivedV2(plan))

  return activePlans.filter((plan: PlanWithServices) =>
    Object.values(plan.services).some((services) =>
      Object.values(services).some(
        (options) => options[requestedOption] && options[requestedOption] !== 'disabled',
      ),
    ),
  )
}

export const getPlansThatContainService = (
  plans: PlanWithServices[],
  requestedService: string,
): PlanWithServices[] => {
  const activePlans = plans.filter((plan) => !isArchivedV2(plan))

  return activePlans.filter((plan: PlanWithServices) =>
    Object.values(plan.services).some((services) =>
      Object.keys(services).some((service) => service === requestedService),
    ),
  )
}

export const minOnePlanHasOption = (
  plans: PlanWithServices[],
  requestedOption: string,
): boolean => {
  const filteredPlans = getPlansThatContainOption(plans, requestedOption)

  return filteredPlans.length > 0
}

export const minOnePlanHasService = (
  plans: PlanWithServices[],
  requestedService: string,
): boolean => {
  const filteredPlans = getPlansThatContainService(plans, requestedService)

  return filteredPlans.length > 0
}

export const getPlansIdToLabel = (plans: PlanWithServices[]) =>
  plans.reduce((acc, plan) => ({ ...acc, [plan.id]: plan.label }), {} as { [id: string]: string })

export const getProvinceName = (isoCode: string, name: string, t: TFunction): string => {
  if (Object.keys(enTranslations.supportedProvinces).includes(isoCode)) {
    return t(`supportedProvinces.${isoCode}`, { defaultValue: '' }) as string
  }
  if (!name) return isoCode
  return `${name} (${isoCode})`
}

export const getSortedArrayPerColumn = (
  items: { label: string; value: string }[],
  colsCount: number,
): { label: string; value: string }[] => {
  if (colsCount < 2) return items
  const itemsCount = items.length
  if (itemsCount < colsCount) return items

  const rowsCount = Math.ceil(itemsCount / colsCount)
  const fullCols = itemsCount % colsCount

  const newItems = [...Array(itemsCount)]

  for (let i = 0, index = 0; i < rowsCount; i++) {
    for (let j = 0; j < colsCount; j++) {
      let newIndex = i + j * rowsCount
      if (fullCols && j >= fullCols + 1) {
        --newIndex
      }
      newItems[index] = items[newIndex]
      index++
      if (index >= itemsCount) break
    }
  }

  return newItems
}
