import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useResetUserPasswordMutation } from 'app/redux/multipassApi'
import { colors } from 'app/theme'

type Props = {
  openResetPasswordModal: boolean
  setOpenResetPasswordModal: (val: boolean) => void
  authId: string
  email?: string | undefined
}

export const ResetPassword: React.FC<Props> = ({
  openResetPasswordModal,
  setOpenResetPasswordModal,
  authId,
  email,
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const EmailContainer = styled('div')({
    display: 'flex',
    padding: '20px 32px',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: colors.white,
    height: '56px',
    boxShadow: '0px 2px 4px 0px rgba(188, 188, 188, 0.50)',
  })

  const [resetUserPassword, { isLoading }] = useResetUserPasswordMutation()

  const handleClose = () => {
    setOpenResetPasswordModal(false)
  }

  const handleResetPassword = async () => {
    try {
      await resetUserPassword({ authId }).unwrap()
      enqueueSnackbar(t('userProfile.actions.successfulResetPassword'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('userProfile.actions.errorResetPassword'), {
        variant: 'error',
      })
    }
    handleClose()
  }

  return (
    <Dialog
      open={openResetPasswordModal}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 760, maxWidth: 'none', backgroundColor: colors.snow },
      }}
    >
      <DialogTitle sx={{ padding: '32px', fontSize: '22px', lineHeight: '29px' }}>
        {t('userProfile.actions.resetPassword')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          sx={{
            position: 'absolute',
            right: 32,
            p: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '32px' }}>
        <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 400 }}>
          {t('userProfile.info.resetEmailConfirmation')}
        </Typography>
        <EmailContainer>
          <Typography variant="subtitle2">{email}</Typography>
        </EmailContainer>
      </DialogContent>
      <DialogActions sx={{ p: '0px 32px 32px' }}>
        <Button variant="outlined" onClick={handleClose} data-testid="close-button">
          {t('global.dialog.cancel')}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={isLoading}
          onClick={handleResetPassword}
          type="submit"
          sx={{ ml: 2 }}
          data-testid="reset-button"
        >
          {t('userProfile.actions.resetPassword')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
