import { SxProps, Theme } from '@mui/material'

export const STRETCH_FULL_HEIGHT = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingBottom: '45px',
} as SxProps<Theme>

export const DEFAULT_PAGE_LIMIT = 50

export const FETCH_ALL_PAGE_SIZE = 500

export const ALL_SPACES_REGEX = / /g
export const ALPHANUMERIC_REGEX = /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9 ]+$/ // Language-inclusive
export const ALL_NON_ALPHANUMERIC_OR_HYPHENS = /[^\w-]+/g

export enum ScribePermission {
  CREATE_PLAN = 'scribe:create:plan',
  UPDATE_PLAN = 'scribe:update:plan',
  LIST_USERS = 'scribe:list:users',
  MANAGE_ORGANIZATIONS = 'scribe:manage:organizations',
  CREATE_ORGANIZATION = 'scribe:create:organization',
  LIST_ELIGIBILITY_RECORDS = 'scribe:list:eligibility_records',
  // FIXME: scribe:update:organization is currently used to view/edit access to organization admin-only
  // attributes. Ideally it should only give access to basic properties, and we would have
  // a scribe:update:organization:admin_fields permission specifically to manage admin-only properties. [see DIA-53483]
  UPDATE_ORGANIZATION = 'scribe:update:organization',
  UPDATE_ORGANIZATION_NAME = 'scribe:update:organization_name',
  UPDATE_USER_EMAIL = 'multipass:update:user:email',
  LIST_INVOICES = 'scribe:list:invoices',
  READ_ORGANIZATION_BILLING = 'scribe:read:organization_billing',
  UPDATE_ORGANIZATION_BILLING = 'scribe:update:organization_billing',
}

export enum FetchBaseQueryErrorStatuses {
  FETCH_ERROR = 'FETCH_ERROR',
  PARSING_ERROR = 'PARSING_ERROR',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  CUSTOM_ERROR = 'CUSTOM_ERROR',
}

export enum ServiceName {
  PRIMARY_CARE = 'primary_care',
  EAP = 'eap',
  ICBT_FAMILY = 'icbt_family',
  ICBT_GUIDED_FAMILY = 'icbt_guided_family',
  MENTAL_HEALTH_FAMILY = 'mental_health_family',
  MENTAL_HEALTH_PLUS_FAMILY = 'mental_health_plus_family',
  FAMILY = 'family',
}

export enum REGIONS {
  CANADA = 'CA',
  UK = 'GB',
  US = 'US',
}

enum AREAS {
  PROVINCE = 'province',
  SUBDIVISION = 'subdivision',
  STATE = 'state',
}

export const AdminAreaTrKeys = {
  [REGIONS.CANADA]: AREAS.PROVINCE,
  [REGIONS.UK]: AREAS.SUBDIVISION,
  [REGIONS.US]: AREAS.STATE,
}

export const CURRENCY_SYMBOLS = {
  DOLLAR: '$',
  POUND: '£',
}

export const REGIONS_CURRENCY_SYMBOLS = {
  [REGIONS.CANADA]: CURRENCY_SYMBOLS.DOLLAR,
  [REGIONS.UK]: CURRENCY_SYMBOLS.POUND,
  [REGIONS.US]: CURRENCY_SYMBOLS.DOLLAR,
}
