import { useTranslation } from 'react-i18next'

import { CONNECTION_TYPES } from '../connections'

import BaseConnection from './BaseConnection'

const Cognito = () => {
  const { t } = useTranslation()
  return (
    <BaseConnection
      title={t('loginConnectionPage.cognito.title')}
      buttonText={t('loginConnectionPage.cognito.loginButton')}
      connection={CONNECTION_TYPES.COGNITO}
    />
  )
}

export default Cognito
