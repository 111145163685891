import { createApi } from '@reduxjs/toolkit/query/react'

import customBaseQuery from 'app/lib/utils/customBaseQuery'
import { UpdateUserEmailArg, ResetUserPasswordArg } from 'app/models/User'
import { getApplicationConfig } from 'config'

import { scribeApi } from './scribeApi'

const {
  multipass: { baseUrl: multipassBaseUrl },
} = getApplicationConfig()

export const multipassApi = createApi({
  reducerPath: 'multipassApi',
  tagTypes: ['User'],
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    updateUserEmail: builder.mutation<null, UpdateUserEmailArg>({
      query: ({ authId, body }) => ({
        url: `${multipassBaseUrl}/users/${authId}/email`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { userId }) => [{ type: 'User', id: userId }],
      extraOptions: {
        showErrorTooltip: true,
        successTooltip: 'userProfile.actions.successfulQuery',
      },
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(scribeApi.util.invalidateTags([{ type: 'User', id: userId }]))
      },
    }),
    resetUserPassword: builder.mutation<null, ResetUserPasswordArg>({
      query: ({ authId }) => ({
        url: `${multipassBaseUrl}/users/${authId}/reset_password`,
        method: 'POST',
      }),
    }),
  }),
})

export const { useUpdateUserEmailMutation, useResetUserPasswordMutation } = multipassApi
