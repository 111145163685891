import { useCallback } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { isValidDate, today, toISOdate, yesterday } from 'app/lib/utils/date'
import { EligibilityDirective } from 'app/models/EligibilityRecord'
import { EligibilityRecord, OrganizationRoute } from 'app/models/scribe.models'
import { useSubmitEligibilityDirectiveMutation } from 'app/redux/scribeApi'

import { ActionType } from './Profile'

type AddEditMemberDialogProps = Readonly<{
  actionType: ActionType
  eligibilityRecord: EligibilityRecord
  onClose: () => void
}>

export type updateRecordStatusParams = Readonly<{
  activationDate: Date | null
  deactivationDate: Date | null
}>

export function UpdateStatusDialog(props: AddEditMemberDialogProps) {
  const { t } = useTranslation()
  const {
    actionType,
    onClose,
    eligibilityRecord: {
      attributes: { uniqueIdentifier, firstName, lastName },
    },
  } = props
  const { organizationId } = useParams() as OrganizationRoute

  const isReactivation = actionType === ActionType.REACTIVATE
  const defaultValues = isReactivation
    ? { activationDate: today(), deactivationDate: null }
    : { activationDate: null, deactivationDate: today() }

  const { control, handleSubmit, reset } = useForm<updateRecordStatusParams>({ defaultValues })
  const [submitDirective, { isLoading }] = useSubmitEligibilityDirectiveMutation()

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  const updateRecordStatus = useCallback(
    ({ activationDate, deactivationDate }: updateRecordStatusParams) => {
      const body: EligibilityDirective = {
        uniqueIdentifier,
        activationDate: activationDate ? toISOdate(activationDate) : undefined,
        deactivationDate: deactivationDate ? toISOdate(deactivationDate) : '#',
      }
      return submitDirective({ organizationId, body }).unwrap().then(handleClose)
    },
    [organizationId, uniqueIdentifier, submitDirective, handleClose],
  )

  const requiredRule: RegisterOptions = {
    required: t('global.isRequired', '', {
      label: t(`eligibilityRecordPage.dialog.${actionType}.date.label`),
    }) as string,
  }

  const isDateRule: RegisterOptions = {
    validate: (val: Date) => {
      if (val && !isValidDate(val)) {
        return t('global.invalidDate') as string
      }
      return true
    },
  }

  return (
    <Page isLoading={isLoading}>
      <Dialog fullWidth maxWidth="xs" open onClose={onClose} data-testid="deactivation-dialog">
        <DialogTitle>{t(`eligibilityRecordPage.dialog.${actionType}.title`)}</DialogTitle>
        <form onSubmit={handleSubmit(updateRecordStatus)} noValidate>
          <DialogContent>
            <Stack spacing={4}>
              {firstName && lastName ? (
                <Typography>
                  {t(`eligibilityRecordPage.dialog.${actionType}.areYouSure`, {
                    firstName,
                    lastName,
                  })}
                </Typography>
              ) : (
                <Typography>
                  {t(`eligibilityRecordPage.dialog.${actionType}.areYouSureNoName`)}
                </Typography>
              )}
              <Controller
                name={isReactivation ? 'activationDate' : 'deactivationDate'}
                control={control}
                rules={isReactivation ? { ...requiredRule, ...isDateRule } : isDateRule}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t(`eligibilityRecordPage.dialog.${actionType}.date.label`)}
                    format="yyyy-MM-dd"
                    openTo="day"
                    views={['year', 'day']}
                    minDate={isReactivation ? today() : yesterday()}
                    slotProps={{
                      textField: {
                        name: 'date',
                        error: !!error,
                        helperText: error?.message,
                        required: isReactivation,
                      },
                    }}
                  />
                )}
              />
              {isReactivation && (
                <Typography>
                  {t(`eligibilityRecordPage.dialog.${actionType}.sendNewMembersAnInviteNotAllowed`)}
                </Typography>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              {t('global.dialog.cancel')}
            </Button>
            <Button variant="contained" type="submit" key="preventDoubleSubmit">
              {t(`global.dialog.${actionType}`)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Page>
  )
}
