import { isWithinInterval } from 'date-fns'

import { fromISOdate, today } from 'app/lib/utils/date'

import { Collection, Item, Paginated, PaginationParams } from './api'
import {
  Address,
  ChargeStrategy,
  CreditCard,
  EligibilityRecord,
  Feature,
  Organization,
  Plan,
  PlanWithServices,
  UsageRange,
} from './scribe.models'

export type GetPlanParams = {
  organizationId: string
  planId: string
}

export type PatchPlanBody = {
  type: 'plan'
  attributes: {
    nameEn: string
    nameFr: string
    label: string
    chargePrice: number
    chargeStrategy: ChargeStrategy
    features: string[]
    isDefault: boolean
  }
  relationships?: {
    cobrand: {
      data: { id: string; type: string }
    }
  }
}

export type PostPlanBody = PatchPlanBody & {
  attributes: {
    descriptionEn: string
    descriptionFr: string
  }
}

export interface UpdateOrganizationPlanArgs extends GetPlanParams {
  body: Item<PatchPlanBody>
}

export interface UpdatePlanArgs {
  body: { startDate: string; endDate: string }
  planId: string
}

export interface AddPlanArgs {
  body: Item<PostPlanBody>
  organizationId: string
}

export type GetFeaturesResponse = Collection<{
  attributes: Feature
}>

export type GetEligiblePeriodsParams = {
  planIds: string[]
  params: UsageRange & PaginationParams
}

export const getCurrentAndOtherPlans = (
  plans: Plan[] = [],
  eligibilityRecord?: EligibilityRecord | null,
): [currentPlan: Plan | undefined, otherPlans: Plan[]] => {
  const lastInterval = eligibilityRecord?.eligibleIntervals.slice(-1)?.[0]
  const currentPlanId = lastInterval?.planId.toString()

  return plans.reduce(
    ([currentPlan, otherPlans]: [Plan | undefined, Plan[]], el) => {
      if (el.id === currentPlanId) return [el, otherPlans]
      else return [currentPlan, [...otherPlans, el]]
    },
    [undefined, []],
  )
}

export const getDefaultAndOtherPlans = (
  plans: Plan[] = [],
): [defaultPlan: Plan | undefined, otherPlans: Plan[]] => {
  let defaultPlan
  const otherPlans = plans.filter((plan) => {
    if (plan.attributes.isDefault) defaultPlan = plan
    return !plan.attributes.isDefault
  })
  return [defaultPlan, otherPlans]
}

// @DEPRECATED: Remove with DIA-52744
export const isArchived = (plan: Plan) => {
  // A plan is archived (non-active) if its during interval DOES NOT include today's date
  return !isWithinInterval(today(), {
    start: fromISOdate(plan.attributes.during.lower),
    end: fromISOdate(plan.attributes.during.upper),
  })
}

export const isArchivedV2 = (plan: PlanWithServices) => {
  // A plan is archived (non-active) if its date intervals DOES NOT include today's date
  return !isWithinInterval(today(), {
    start: fromISOdate(plan.startDate),
    end: fromISOdate(plan.endDate),
  })
}

export type ListOrganizationResponse = Collection<OrganizationData> & {
  links: {
    self: {
      meta: {
        queryParameters: {
          limit: number
          offset: number
        }
        searchResultsCount: number
      }
    }
  }
}

export type ListOrganizationsParams = {
  limit?: number
  offset?: number
  search?: string
}
type V1Object<name, T> = {
  id: string
  type: name
  attributes: Omit<T, 'id'>
}
type OrganizationData = V1Object<'organization', Organization> & {
  links: {
    members: {
      meta: {
        activeMembers: { count: number }
        futureMembers: { count: number }
        inactiveMembers: { count: number }
      }
    }
  }
}
export type IncludedCreditCard = V1Object<'organization_credit_card', CreditCard>

export type IncludedAddress = V1Object<'organization_address', Address>

export type OrganizationIncluded = Array<IncludedCreditCard | IncludedAddress>

export type GetOrganizationResponse = Item<OrganizationData> & {
  included: OrganizationIncluded
}

export function fromV1ListOrganization(
  response: ListOrganizationResponse,
): Paginated<Organization> {
  const organizations = response.data.map<Organization>((v1Org) => ({
    id: v1Org.id,
    ...v1Org.attributes,
    members: {
      active: v1Org.links.members.meta.activeMembers.count,
      future: v1Org.links.members.meta.futureMembers.count,
      inactive: v1Org.links.members.meta.inactiveMembers.count,
    },
  }))
  const meta = response.links.self.meta
  const pagination = {
    offset: meta.queryParameters.offset,
    totalItems: meta.searchResultsCount,
  }

  return { data: organizations, meta: pagination }
}

export function fromV1Organization(response: GetOrganizationResponse): Organization {
  const default_credit_card = response.included.find<IncludedCreditCard>(
    (x): x is IncludedCreditCard => x.type === 'organization_credit_card',
  )
  const addresses = response.included.filter<IncludedAddress>(
    (x): x is IncludedAddress => x.type === 'organization_address',
  )
  const organizationData = response.data
  return {
    id: organizationData.id,
    ...organizationData.attributes,
    defaultCreditCard: default_credit_card
      ? { id: default_credit_card.id, ...default_credit_card.attributes }
      : undefined,
    addresses: addresses.map((address) => ({ id: address.id, ...address.attributes })),
    members: {
      active: organizationData.links.members.meta.activeMembers.count,
      future: organizationData.links.members.meta.futureMembers.count,
      inactive: organizationData.links.members.meta.inactiveMembers.count,
    },
  }
}

export type OrganizationAttributes = {
  name: string
  emailPreference: string
  billingMethod?: string
  billingStartDate: string
  accountingMethod: string
  billingEmail: string
  memberIdType: string
  communicationsAllowed: boolean
  reportsEnabled: boolean
  clientNotes?: string
  careTeamNotes?: string
  forcedMfa?: boolean
  displayName?: {
    en?: string
    fr?: string
  }
  meta?: {
    organizationEnrolmentCode: string | null
  }
  attribute_schema?: {
    reporting_column_one: string | null
    reporting_column_two: string | null
    reporting_column_three: string | null
    reporting_column_four: string | null
    reporting_column_five: string | null
    reporting_column_six: string | null
    reporting_column_seven: string | null
  }
}

export type PostOrganizationBody = {
  type: 'organization'
  attributes: OrganizationAttributes
}

export type AddOrganizationArgs = {
  body: Item<PostOrganizationBody>
}

export type PatchOrganizationBody = {
  type: 'organization'
  attributes: Partial<Omit<OrganizationAttributes, 'accountingMethod' | 'billingStartDate'>>
}

export type UpdateOrganizationArgs = {
  body: Item<PatchOrganizationBody>
  organizationId: string
}

export type PostOrganizationAddressBody = {
  type: 'organization_address'
  attributes: {
    streetAddress?: string
    suite?: string
    postalCode?: string
    city?: string
    adminAreaIsoCode?: string
  }
}

export type AddOrganizationAddressArgs = {
  organizationId: string
  body: Item<PostOrganizationAddressBody>
}

export type PatchOrganizationAddressBody = Partial<PostOrganizationAddressBody>

export type UpdateOrganizationAddressArgs = {
  organizationId: string
  addressId: string
  body: Item<PatchOrganizationAddressBody>
}

export type UpdateOrganizationContentTagsBody = {
  names: string[]
}
export interface UpdateOrganizationContentTagsArgs {
  organizationId: string
  body: UpdateOrganizationContentTagsBody
}
