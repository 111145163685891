import { ChangeEvent, useState } from 'react'

import { Card, CardContent, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import InputFormField from 'app/lib/components/form/InputFormField'
import SwitchFormField from 'app/lib/components/form/SwitchFormField'

const enrolmentCodeName = 'enrolmentCode'

export const Attributes: React.FC<{ nonEditableFields: string[] }> = ({ nonEditableFields }) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()

  const enrolmentCode = watch(enrolmentCodeName)
  const [preservedCode, setPreservedCode] = useState<string>(enrolmentCode)
  const [isCodeSwitchActive, setCodeSwitchActive] = useState<boolean>(!!enrolmentCode)

  const handleCodeSwitchChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked && enrolmentCode) {
      if (preservedCode !== enrolmentCode) {
        setPreservedCode(enrolmentCode)
      }
      setValue(enrolmentCodeName, '', { shouldDirty: true })
    }
    if (checked && preservedCode) {
      setValue(enrolmentCodeName, preservedCode, { shouldDirty: true })
    }
    setCodeSwitchActive(checked)
  }

  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" mb={2}>
              {t('organizationPage.form.attributes.title')}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="forcedMfa"
                label={t('organizationPage.form.attributes.forcedMfa')}
                disabled={nonEditableFields.includes('forcedMfa')}
                defaultChecked
                color="warning"
              />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="reportsEnabled"
                label={t('organizationPage.form.attributes.reportsEnabled')}
                disabled={nonEditableFields.includes('reportsEnabled')}
                color="warning"
              />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="communicationsAllowed"
                label={t('organizationPage.form.attributes.communicationsAllowed')}
                color="warning"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12} md={4} display="flex" alignItems="center" height={60}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleCodeSwitchChange}
                    checked={isCodeSwitchActive}
                    inputProps={
                      {
                        'data-testid': 'enrolment-code-enabled-switch',
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                }
                disabled={nonEditableFields.includes(enrolmentCodeName)}
                label={t('organizationPage.form.attributes.enrolmentCodeEnabled')}
              />
            </Grid>
            {isCodeSwitchActive && (
              <Grid item xs={12} md={6}>
                <InputFormField
                  name="enrolmentCode"
                  disabled={nonEditableFields.includes(enrolmentCodeName)}
                  variant="outlined"
                  hideLabel
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
